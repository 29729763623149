import axios from 'axios';
import { API_URL } from '../api-utils';
import { authHeader } from '../auth-header';

export const marketingService = {
    sendEnquiry,
    subscribe,
    confirmSubscribe,
    sendMobileNotification,
    listMobileNotification
}

async function listMobileNotification() {
    return await axios.get(`${API_URL}/marketing/list_mobile_notification/`, {
        headers: authHeader()
    })
}

async function sendMobileNotification(payload) {
    return await axios.post(`${API_URL}/marketing/send_notification_message/`, payload, {
        headers: authHeader()
    })
}

async function confirmSubscribe(payload) {

    const headers = {
        'Content-Type': 'application/json'
    }

    return await axios.post(`${API_URL}/marketing/confirm_email/`, payload, headers)
}


async function subscribe(payload) {

    const headers = {
        'Content-Type': 'application/json'
    }

    return await axios.post(`${API_URL}/marketing/subscribe/`, payload, headers)
}


async function sendEnquiry(payload) {

    const headers = {
        'Content-Type': 'application/json'
    }

    return await axios.post(`${API_URL}/marketing/send_enquiry/`, payload, headers)
}