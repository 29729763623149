import { marketingService } from '@/api/services/marketing';

export const state = {
    notifications: []
}

export const getters = {
    notifications: state => state.notifications
}


export const mutations = {
    SET_NOTIFICATIONS(state, data) {
        state.notifications = data
    }
}


export const actions = {
    async listMobileNotification({ commit }) {
        const response = await marketingService.listMobileNotification()
        commit('SET_NOTIFICATIONS', response.data)
    },
    async sendMobileNotification({ commit }, payload) {
        const response = await marketingService.sendMobileNotification(payload)
        return response
    },

    async confirmSubscribe({ commit }, payload) {
        const response = await marketingService.confirmSubscribe(payload)
        return response
    },

    
    async subscribe({ commit }, payload) {
        const response = await marketingService.subscribe(payload)
        return response
    },

    async sendEnquiry({ commit }, payload) {
        const response = await marketingService.sendEnquiry(payload)
        console.log(response)
        return response
    }
}